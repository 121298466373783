<template>
  <v-container>
    <v-flex class="d-flex justify-center">
      <v-card width="500" v-if="paymentInfo"
        ><v-system-bar class="pa-4" color="#ebb63b"> </v-system-bar>
        <v-stepper v-model="stepper">
          <v-stepper-step color="#ebb63b" :complete="stepper > 1" step="1"
            ><strong>Persoonlijke gegevens</strong></v-stepper-step
          >
          <v-stepper-content step="1">
            <v-text-field
              label="Lidnummer"
              v-model="paymentInfo.id"
              disabled
            ></v-text-field>
            <v-text-field
              label="Voornaam"
              v-model="paymentInfo.firstName"
              @change="paymentInfo.changed = true"
            ></v-text-field>
            <v-text-field
              label="Achternaam (verplicht)"
              v-model="paymentInfo.lastName"
              @change="paymentInfo.changed = true"
            ></v-text-field>
            <v-text-field
              label="Email adres (verplicht)"
              v-model="paymentInfo.email"
              @change="paymentInfo.changed = true"
              :rules="[emailRules]"
            ></v-text-field>
            <v-text-field
              label="Adres (verplicht)"
              v-model="paymentInfo.adres"
              @change="paymentInfo.changed = true"
            ></v-text-field>
            <v-text-field
              label="Postcode (verplicht)"
              v-model="paymentInfo.postalCode"
              @change="paymentInfo.changed = true"
            ></v-text-field>
            <v-text-field
              label="Stad (verplicht)"
              v-model="paymentInfo.city"
              @change="paymentInfo.changed = true"
            ></v-text-field>
            <v-select
              :items="countries"
              label="Land (verplicht)"
              v-model="paymentInfo.country"
              @change="paymentInfo.changed = true"
            ></v-select>
            <v-checkbox
              v-model="paymentInfo.contactPermission"
              @change="paymentInfo.changed = true"
              label="Heeft AHC de Vossen toestemming om u via mail te contacteren over andere Carnaval gerelateerde zaken? U kunt hier bij bijvoorbeeld denken aan een mailtje met het programma vlak voor de start van Carnaval"
            ></v-checkbox>
            <v-checkbox
              v-model="paymentInfo.oorkonde"
              @change="paymentInfo.changed = true"
              label="Wenst u jaarlijks een oorkonde van lidmaatschap te ontvangen?"
            >
            </v-checkbox>
            <v-btn v-if="paymentInfo.email" color="#ebb63b" @click="stepper = 2"
              >Volgende</v-btn
            >
          </v-stepper-content>
          <v-stepper-step
            color="#ebb63b"
            :complete="stepper > 2"
            step="2"
            v-if="paymentInfo.buut"
            ><strong>Buutkaarten</strong></v-stepper-step
          >
          <v-stepper-content step="2" v-if="paymentInfo.buut">
            <span
              >Dit jaar kunnen er per lid van de Club van 111 maximaal
              <strong>{{ paymentInfo.buutMax }}</strong> kaarten besteld worden.
              De kaarten kosten
              <strong>€{{ paymentInfo.buutPrice }}</strong> per stuk en kunnen
              besteld worden tot uiterlijk
              <strong>{{ paymentInfo.buutDeadline }}</strong
              >. <br /><small
                >Kaarten kunnen enkel via deze weg besteld worden en na betaling
                van de contributie is het <strong>niet</strong> mogelijk om nog
                extra kaarten bij te bestellen.</small
              ></span
            >
            <v-select
              v-model="paymentInfo.buutAmount"
              label="Hoeveel kaarten wilt u bestellen?"
              :items="buutAmount"
              v-if="checkDeadline"
            ></v-select>
            <v-select
              v-model="paymentInfo.buutAmount"
              label="De deadline is verlopen"
              :items="buutAmount"
              v-if="!checkDeadline"
              disabled
            ></v-select>
            <v-btn color="#ebb63b" @click="(stepper = 3), checkIsNan()"
              >Volgende</v-btn
            >
            <v-btn text @click="stepper -= 1">Terug</v-btn>
          </v-stepper-content>
          <v-stepper-step
            color="#ebb63b"
            :complete="stepper > 3"
            step="3"
            v-if="paymentInfo.buut"
            ><strong>Overzicht</strong></v-stepper-step
          >
          <v-stepper-content step="3" v-if="paymentInfo.buut">
            <div>
              <v-row class="ma-3">
                <span
                  >1 x contributie:
                  <strong>€{{ paymentInfo.contribution }}</strong></span
                >
              </v-row>
              <v-row class="ma-3">
                <span
                  >{{ paymentInfo.buutAmount }} x buutkaarten:
                  <strong
                    >€{{
                      paymentInfo.buutAmount * paymentInfo.buutPrice
                    }}</strong
                  ></span
                >
              </v-row>
              <v-divider></v-divider>
              <v-row class="ma-3">
                <span
                  ><strong
                    >Totaal bedrag: €{{
                      (parseFloat(paymentInfo.contribution.replace(/,/g, '.')) +
                      (paymentInfo.buutAmount * parseFloat(paymentInfo.buutPrice.replace(/,/g, '.')))).toFixed(2).replace(/\./g, ',')
                    }}</strong
                  ></span
                >
              </v-row>
            </div>

            <v-btn color="#ebb63b" @click="createClubPayment"
              >Naar betalen</v-btn
            >
            <v-btn text @click="stepper -= 1">Terug</v-btn>
          </v-stepper-content>
          <v-stepper-step
            color="#ebb63b"
            :complete="stepper > 2"
            step="2"
            v-if="!paymentInfo.buut"
            ><strong>Overzicht</strong></v-stepper-step
          >
          <v-stepper-content step="2" v-if="!paymentInfo.buut">
            <div>
              <v-row class="ma-3">
                <span
                  >1 x contributie:
                  <strong>€{{ paymentInfo.contribution }}</strong></span
                >
              </v-row>
              <v-divider></v-divider>
              <v-row class="ma-3">
                <span
                  ><strong
                    >Totaal bedrag: €{{ paymentInfo.contribution }}</strong
                  ></span
                >
              </v-row>
            </div>

            <v-btn color="#ebb63b" @click="createClubPayment"
              >Naar betalen</v-btn
            >
            <v-btn text @click="stepper -= 1">Terug</v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-flex>
    <v-overlay opacity="0.8" v-if="buzzy">
      <v-row class="d-flex justify-space-around mb-6">
        <v-progress-circular
          class="ma-5 ahcRed"
          :size="70"
          :width="7"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-row class="d-flex justify-space-around mb-6">
        <h3>Nieuwe betaling creëren</h3>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      stepper: 1,
      countries: ["Nederland", "België"],
      emailRules: (v) =>
        /.+@.+\..+/.test(v) || "E-mail voldoet niet aan het juiste formaat",
      buzzy: false,
    };
  },
  computed: {
    paymentInfo() {
      return this.$store.state.clubPayment;
    },
    buutAmount() {
      let items = [];
      for (let i = 0; i <= this.paymentInfo.buutMax; i++) {
        items.push(i);
      }
      return items;
    },
    checkDeadline() {
      let check = false;
      let date = new Date();
      let dd = String(date.getDate()).padStart(2, "0");
      let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = date.getFullYear();
      let checkDate = yyyy + "-" + mm + "-" + dd;
      if (this.paymentInfo.buutDeadline >= checkDate) {
        check = true;
      }
      return check;
    },
  },
  methods: {
    createClubPayment() {
      this.buzzy = true;
      this.$store.dispatch("createClubPayment", { payment: this.paymentInfo });
      console.log("🚀 ~ file: ClubPayment.vue:238 ~ createClubPayment ~ this.paymentInfo:", this.paymentInfo)
    },
    checkIsNan() {
      this.paymentInfo.buutAmount = this.paymentInfo.buutAmount || 0;
    },
  },
};
</script>

<style>
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
</style>